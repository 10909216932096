#background-video{
    height: 100%;
    width: 100%;
    float: left;
    top: 0;
    padding: none;
    /* position: fixed; */
}

@media (min-width:320px)  { 
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
}
@media (min-width:480px)  { 
    /* smartphones, Android phones, landscape iPhone */ 
}
@media (min-width:600px)  { 
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
}
@media (min-width:801px)  { 
    /* tablet, landscape iPad, lo-res laptops ands desktops */ 
}
@media (min-width:1025px) { 
    /* big landscape tablets, laptops, and desktops */ 
    #background-video{
        position: fixed;
    }
}
@media (min-width:1281px) { 
    /* hi-res laptops and desktops */ 
    #background-video{
        position: fixed;
    }
}

.newyear-2023-countdown {
    font-family: 'DancingScript';
    color: #000;
}

