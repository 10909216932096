h1 {
  font-size: 8vh;
  font-weight: 100;
  font-family: 'RampartOne';
  color: #ffffff;
  text-shadow: 0 0 0 transparent, 0 0 10px #bc365b, 0 0 20px #bc365b, 0 0 40px transparent;
  text-align: center;
  animation: blink 4s infinite alternate;
}

h6 {
  font-size: 1.5vh;
  text-align: center;
}

@keyframes blink {
  50% {
    opacity: 1;
  }
  52% {
    opacity: 0.8;
  }
  53% {
    opacity: 1;
  }
  55% {
    opacity: 0.2;
  }
  56% {
    opacity: 1;
  }
}