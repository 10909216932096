@font-face {
  font-family: 'Wilde';
  /* font-style: bold; */
  font-weight: 800;
  src: url('./fonts/Wilde.ttf'); /* IE9 Compat Modes */
  src: local('Wilde'),
       url('./fonts/Wilde.ttf') format('truetype')
}

@font-face {
  font-family: 'DancingScript';
  /* font-style: bold; */
  /* font-weight: 800; */
  src: url('./fonts/DancingScript-SemiBold.ttf'); /* IE9 Compat Modes */
  src: local('DancingScript'),
       url('./fonts/DancingScript-SemiBold.ttf') format('truetype')
}

@font-face {
  font-family: 'Charlotte';
  /* font-style: bold; */
  /* font-weight: 800; */
  src: url('./fonts/Charlotte.ttf'); /* IE9 Compat Modes */
  src: local('Charlotte'),
       url('./fonts/Charlotte.ttf') format('truetype')
}

@font-face {
  font-family: 'RampartOne';
  /* font-style: bold; */
  /* font-weight: 800; */
  src: url('./fonts/RampartOne-Regular.ttf'); /* IE9 Compat Modes */
  src: local('RampartOne'),
       url('./fonts/RampartOne-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'SpecialElite';
  /* font-style: bold; */
  /* font-weight: 800; */
  src: url('./fonts/SpecialElite-Regular.ttf'); /* IE9 Compat Modes */
  src: local('SpecialElite'),
       url('./fonts/SpecialElite-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'RubikMaze';
  /* font-style: bold; */
  /* font-weight: 800; */
  src: url('./fonts/RubikMaze-Regular.ttf'); /* IE9 Compat Modes */
  src: local('RubikMaze'),
       url('./fonts/RubikMaze-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'FredokaOne';
  /* font-style: bold; */
  /* font-weight: 800; */
  src: url('./fonts/FredokaOne-Regular.ttf'); /* IE9 Compat Modes */
  src: local('FredokaOne'),
       url('./fonts/FredokaOne-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'Satisfy';
  /* font-style: bold; */
  /* font-weight: 800; */
  src: url('./fonts/Satisfy-Regular.ttf'); /* IE9 Compat Modes */
  src: local('Satisfy'),
       url('./fonts/Satisfy-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'Staatliches';
  /* font-style: bold; */
  /* font-weight: 800; */
  src: url('./fonts/Staatliches-Regular.ttf'); /* IE9 Compat Modes */
  src: local('Staatliches'),
       url('./fonts/Staatliches-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'LilitaOne';
  /* font-style: bold; */
  /* font-weight: 800; */
  src: url('./fonts/LilitaOne-Regular.ttf'); /* IE9 Compat Modes */
  src: local('LilitaOne'),
       url('./fonts/LilitaOne-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'Sriracha';
  /* font-style: bold; */
  /* font-weight: 800; */
  src: url('./fonts/Sriracha-Regular.ttf'); /* IE9 Compat Modes */
  src: local('Sriracha'),
       url('./fonts/Sriracha-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'Ruwudu';
  /* font-style: bold; */
  /* font-weight: 800; */
  src: url('./fonts/Ruwudu-Regular.ttf'); /* IE9 Compat Modes */
  src: local('Ruwudu'),
       url('./fonts/Ruwudu-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'DaiBannaSIL';
  /* font-style: bold; */
  /* font-weight: 800; */
  src: url('./fonts/DaiBannaSIL-Regular.ttf'); /* IE9 Compat Modes */
  src: local('DaiBannaSIL'),
       url('./fonts/DaiBannaSIL-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'Feather';
  /* font-style: bold; */
  /* font-weight: 800; */
  src: url('./fonts/Feather-Bold.ttf'); /* IE9 Compat Modes */
  src: local('Feather'),
       url('./fonts/Feather-Bold.ttf') format('truetype')
}

@font-face {
  font-family: 'DINNextRounded';
  /* font-style: bold; */
  /* font-weight: 800; */
  src: url('./fonts/DIN-Next-Rounded-Regular.ttf'); /* IE9 Compat Modes */
  src: local('DINNextRounded'),
       url('./fonts/DIN-Next-Rounded-Regular.ttf') format('truetype')
}

.App {
  /* text-align: center;
  color: white;
  font-family: 'DancingScript'; */
}

.App-background {
  /* text-align: center;
  color: white;
  font-family: 'Wilde';
  align-items: center;
  justify-content: center; */
  display: flex;
  min-height: 100vh;
  /* background-image: url('./images/image_moon.jpeg'); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: brightness(50%);
  -webkit-filter: brightness(50%);
}

.App-video-background {
  height: 100%;
  width: 100%;
  float: left;
  top: 0;
  padding: none;
  position: fixed;
}

.App-background-first {
  background-image: url('./images/image_moon.jpeg');
}

.App-background-third {
  background-image: url('./images/background.png');
}

.App-background-birthday {
  background-image: url('./images/lover.png');
}

.App-background-new-year {
  background-image: url('./images/image_moon.jpeg');
}

.App-background-first-meeting {
  background-image: url('./images/donut_meeting.png');
}

.App-background-bday-2024 {
  background-image: url('./images/bday_2024.png');
}

.App-background-vday-2025 {
  display: flex;
  min-height: 100vh;
  background-color: #5cfbac;
  justify-content: center;
  align-items: center;
}

.App-four-years {
  text-align: center;
  color: white;
  font-family: 'DancingScript';
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 100vh;
  background-image: url('./images/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: brightness(50%);
  -webkit-filter: brightness(50%);
}

.App-header-blurred {
  background-color: #333c5d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; 
  background-image: url('./images/image_moon.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: blur(8px) brightness(50%);
  -webkit-filter: blur(8px) brightness(50%);
}

.App-header {
  background-color: #333c5d;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white; 
  /* text-align: center;
  background-color: #FFFFFF;
  color: #FCFCFC;
  min-height: 100vh; */
  background-image: url('./images/image_moon.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  filter: brightness(50%);
}

.App-content {
  color: white;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-content-first {
  font-family: 'Wilde';
}

.App-content-third {
  font-family: 'DancingScript';
}

.App-content-birthday {
  font-family: 'SpecialElite';
}

.App-content-six-years {
  font-family: 'Staatliches';
}

.App-content-first-meet {
  font-family: 'DaiBannaSIL';
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-sign-in {
  padding: 10px;
  background-color: #4285F4;
  color: aliceblue;
  border-radius: 5px;
}

.App-animated-nick {
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 20;
}

.App-dropdown {
  position: absolute;
  right: 10px;
  top: 10px;
  /* z-index: 100; */
  /* color: transparent; */
  /* font: serif;
  font-size: small; */
}

.App-spotify-dropdown {
  position: absolute;
  left: 5px;
  top: 5px;
  z-index: 20;
  color: transparent;
  font: serif;
  font-size: small;
}
