/*
 * Variable definitions
 */
/*
 * Animations
 */
 @keyframes shine {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.6);
  }
}
@keyframes glow {
  0% {
    box-shadow: 0 0 1em #ffffff, 0 0 -1em #ffffff;
    opacity: 1;
    transform: scale(0);
  }
  100% {
    box-shadow: 0 0 1em #ffffff;
    opacity: 0;
    transform: scale(2.5);
  }
}
@keyframes blink {
  0%, 95% {
    transform: scaleY(1);
  }
  98%, 100% {
    transform: scaleY(0.1);
  }
}
@keyframes float {
  0% {
    transform: translateY(-5%);
  }
  100% {
    transform: translateY(5%);
  }
}
@keyframes lookaround {
  0%, 15% {
    transform: rotate(-10deg);
  }
  20%, 50%, 80% {
    transform: rotate(0);
  }
  85%, 100% {
    transform: rotate(10deg);
  }
}
@keyframes heartbeat {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  2% {
    opacity: 0.5;
    transform: scale(1.3);
  }
  4% {
    opacity: 1;
    transform: scale(1);
  }
  8% {
    transform: scale(1.1);
  }
  10% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1) translate(0, -800%);
  }
}
@keyframes textfloat {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  2% {
    opacity: 0.5;
    transform: scale(1.1) translate(0, -20%);
  }
  4% {
    opacity: 1;
    transform: scale(1) translate(0, -40%);
  }
  8% {
    transform: scale(1.1) translate(0, -80%);
  }
  10% {
    transform: scale(1) translate(0, -100%);
  }
  100% {
    opacity: 0;
    transform: scale(1) translate(0, -800%);
  }
}
/*
 * Element styling
 */
html, body {
  height: 100%;
  font-size: 100%;
  margin: 0;
  overflow: hidden;
  width: 100%;
}
.space {
  background-color: #20263b;
  height: 100%;
  position: relative;
}
.space__star {
  background-color: #FFFDE7;
  border-radius: 50%;
  position: absolute;
}
.space__star:after {
  background-color: #fafafa;
  border-radius: 50%;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
}
.space__star:nth-child(even) {
  background-color: #E1F5FE;
}
.space__star--small {
  height: 0.5em;
  width: 0.5em;
}
.space__star--medium {
  height: 1em;
  width: 1em;
}
.space__star--big {
  height: 1.5em;
  width: 1.5em;
}
.space__star--1 {
  animation: shine 5s ease-in-out infinite alternate;
  left: 12%;
  top: 3%;
}
.space__star--1::after {
  animation: glow 5s ease-out infinite normal;
}
.space__star--2 {
  animation: shine 2s ease-in-out infinite alternate;
  left: 78%;
  top: 3%;
}
.space__star--2::after {
  animation: glow 6s ease-out infinite normal;
}
.space__star--3 {
  animation: shine 1s ease-in-out infinite alternate;
  left: 92%;
  top: 20%;
}
.space__star--3::after {
  animation: glow 3s ease-out infinite normal;
}
.space__star--4 {
  animation: shine 2s ease-in-out infinite alternate;
  left: 63%;
  top: 35%;
}
.space__star--4::after {
  animation: glow 6s ease-out infinite normal;
}
.space__star--5 {
  animation: shine 5s ease-in-out infinite alternate;
  left: 28%;
  top: 18%;
}
.space__star--5::after {
  animation: glow 6s ease-out infinite normal;
}
.space__star--6 {
  animation: shine 2s ease-in-out infinite alternate;
  left: 34%;
  top: 55%;
}
.space__star--6::after {
  animation: glow 6s ease-out infinite normal;
}
.space__star--7 {
  animation: shine 1s ease-in-out infinite alternate;
  left: 34%;
  top: 46%;
}
.space__star--7::after {
  animation: glow 5s ease-out infinite normal;
}
.space__star--8 {
  animation: shine 1s ease-in-out infinite alternate;
  left: 89%;
  top: 83%;
}
.space__star--8::after {
  animation: glow 3s ease-out infinite normal;
}
.space__star--9 {
  animation: shine 4s ease-in-out infinite alternate;
  left: 24%;
  top: 83%;
}
.space__star--9::after {
  animation: glow 5s ease-out infinite normal;
}
.space__star--10 {
  animation: shine 6s ease-in-out infinite alternate;
  left: 11%;
  top: 4%;
}
.space__star--10::after {
  animation: glow 3s ease-out infinite normal;
}
.space__star--11 {
  animation: shine 4s ease-in-out infinite alternate;
  left: 70%;
  top: 22%;
}
.space__star--11::after {
  animation: glow 2s ease-out infinite normal;
}
.space__star--12 {
  animation: shine 1s ease-in-out infinite alternate;
  left: 98%;
  top: 100%;
}
.space__star--12::after {
  animation: glow 3s ease-out infinite normal;
}
.space__star--13 {
  animation: shine 6s ease-in-out infinite alternate;
  left: 78%;
  top: 86%;
}
.space__star--13::after {
  animation: glow 4s ease-out infinite normal;
}
.space__star--14 {
  animation: shine 1s ease-in-out infinite alternate;
  left: 20%;
  top: 13%;
}
.space__star--14::after {
  animation: glow 3s ease-out infinite normal;
}
.space__star--15 {
  animation: shine 1s ease-in-out infinite alternate;
  left: 71%;
  top: 16%;
}
.space__star--15::after {
  animation: glow 6s ease-out infinite normal;
}
.space__star--16 {
  animation: shine 5s ease-in-out infinite alternate;
  left: 7%;
  top: 90%;
}
.space__star--16::after {
  animation: glow 6s ease-out infinite normal;
}
.space__star--17 {
  animation: shine 3s ease-in-out infinite alternate;
  left: 2%;
  top: 87%;
}
.space__star--17::after {
  animation: glow 6s ease-out infinite normal;
}
.space__star--18 {
  animation: shine 6s ease-in-out infinite alternate;
  left: 90%;
  top: 21%;
}
.space__star--18::after {
  animation: glow 2s ease-out infinite normal;
}
.space__star--19 {
  animation: shine 3s ease-in-out infinite alternate;
  left: 36%;
  top: 93%;
}
.space__star--19::after {
  animation: glow 2s ease-out infinite normal;
}
.space__star--20 {
  animation: shine 4s ease-in-out infinite alternate;
  left: 33%;
  top: 54%;
}
.space__star--20::after {
  animation: glow 4s ease-out infinite normal;
}
.space__star--21 {
  animation: shine 3s ease-in-out infinite alternate;
  left: 53%;
  top: 51%;
}
.space__star--21::after {
  animation: glow 5s ease-out infinite normal;
}
.space__star--22 {
  animation: shine 1s ease-in-out infinite alternate;
  left: 14%;
  top: 53%;
}
.space__star--22::after {
  animation: glow 5s ease-out infinite normal;
}
.space__star--23 {
  animation: shine 7s ease-in-out infinite alternate;
  left: 94%;
  top: 62%;
}
.space__star--23::after {
  animation: glow 4s ease-out infinite normal;
}
.space__star--24 {
  animation: shine 1s ease-in-out infinite alternate;
  left: 23%;
  top: 45%;
}
.space__star--24::after {
  animation: glow 5s ease-out infinite normal;
}
.space__star--25 {
  animation: shine 5s ease-in-out infinite alternate;
  left: 37%;
  top: 47%;
}
.space__star--25::after {
  animation: glow 5s ease-out infinite normal;
}
.space__star--26 {
  animation: shine 5s ease-in-out infinite alternate;
  left: 5%;
  top: 78%;
}
.space__star--26::after {
  animation: glow 2s ease-out infinite normal;
}
.space__star--27 {
  animation: shine 6s ease-in-out infinite alternate;
  left: 96%;
  top: 8%;
}
.space__star--27::after {
  animation: glow 6s ease-out infinite normal;
}
.space__star--28 {
  animation: shine 7s ease-in-out infinite alternate;
  left: 29%;
  top: 54%;
}
.space__star--28::after {
  animation: glow 5s ease-out infinite normal;
}
.space__star--29 {
  animation: shine 4s ease-in-out infinite alternate;
  left: 9%;
  top: 50%;
}
.space__star--29::after {
  animation: glow 6s ease-out infinite normal;
}
.moon {
  animation: float 5s ease-in-out infinite alternate;
  background-color: #cccccc;
  border-radius: 50%;
  border: 0.75em solid #333c5d;
  height: 10em;
  left: 5%;
  margin-top: -11em;
  position: absolute;
  bottom: 10%;
  width: 10em;
}
.moon__container {
  animation: lookaround 15s infinite alternate;
  height: 100%;
}
.moon__crater {
  animation: lookaround 15s infinite alternate-reverse;
  background-color: #c2c2c2;
  border-radius: 50%;
  box-shadow: inset -0.3em 0.2em 0 rgba(0, 0, 0, 0.1);
  position: absolute;
}
.moon__crater--small {
  height: 0.8em;
  width: 0.8em;
}
.moon__crater--medium {
  height: 1.2em;
  width: 1.2em;
}
.moon__crater--big {
  height: 1.9em;
  width: 1.9em;
}
.moon__crater--1 {
  top: 9%;
  left: 35%;
}
.moon__crater--2 {
  top: 25%;
  left: 40%;
}
.moon__crater--3 {
  top: 69%;
  left: 15%;
}
.moon__crater--4 {
  top: 83%;
  left: 41%;
}
.moon__crater--5 {
  top: 76%;
  left: 55%;
}
.moon__crater--6 {
  top: 16%;
  left: 48%;
}
.moon__crater--7 {
  top: 39%;
  left: 15%;
}
.moon__face {
  height: 25%;
  right: 5%;
  position: absolute;
  top: 40%;
  width: 50%;
}
.moon__eye {
  animation: blink 2s infinite alternate;
  background-color: #000000;
  border-radius: 50%;
  height: 0.75em;
  overflow: hidden;
  position: absolute;
  top: 10;
  width: 0.75em;
}
.moon__eye::before, .moon__eye::after {
  animation: shine 1s infinite alternate;
  background-color: #fafafa;
  border-radius: 50%;
  content: "";
  display: block;
  height: 40%;
  opacity: 0.95;
  position: absolute;
  width: 40%;
}
.moon__eye::after {
  bottom: 0;
  height: 60%;
  right: 0;
  width: 60%;
}
.moon__eye--left {
  left: 0;
}
.moon__eye--right {
  right: 0;
}
.moon__cheek {
  background: #ef9a9a;
  border-radius: 40%;
  height: 0.5em;
  position: absolute;
  top: 38%;
  width: 1em;
}
.moon__cheek--left {
  left: -1em;
}
.moon__cheek--right {
  right: -1em;
}
.moon__smile {
  border: 0.25em solid transparent;
  border-left-color: #000000;
  border-bottom-color: #000000;
  border-radius: 50%;
  height: 0.5em;
  left: 50%;
  position: absolute;
  transform: rotate(-45deg);
  transition: transform 0.2s;
  width: 0.5em;
}
.love {
  position: absolute;
  right: 1em;
  top: 3em;
}
.love__heart {
  animation: heartbeat 4s infinite normal backwards;
  height: 1em;
  position: absolute;
  width: 1em;
}
.love__heart--1 {
  animation-delay: 0.5s;
  left: 1.5em;
}
.love__heart--2 {
  animation-delay: 1s;
  left: 3em;
}
.love__heart--3 {
  animation-delay: 1.5s;
  left: 0em;
}
.love__heart::before, .love__heart::after {
  background: #ef9a9a;
  border-radius: 50% 50% 0 0;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 65%;
}
.love__heart::before {
  transform: rotate(-35deg) translateX(-40%);
}
.love__heart::after {
  transform: rotate(55deg) translateX(40%);
}
.chat {
  position: absolute;
  right: -1em;
  top: -1em;
  background-color: transparent;
}
.chat_item {
  animation: textfloat 29s infinite normal backwards;
  height: 2em;
  position: absolute;
  width: 10em;
  right: -5em;
  top: -1em;
  font-size: 1.75em;
}
/* .chat_item::before, .chat_item::after {
  background: #ffffff;
  border-radius: 5%;
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  width: 200%;
  
} */
.chat_item--1 {
  animation-delay: 0.1s;
  /* content: "Hello Nivedita!"; */
}
.chat_item--2 {
  animation-delay: 5s;
  right: -5em;
}
.chat_item--3 {
  animation-delay: 10s;
  right: -6em;
}
.chat_item--4 {
  animation-delay: 15s;
  right: -4em;
}
.chat_item--5 {
  animation-delay: 15s;
  right: -5em;
}
.chat_item--6 {
  animation-delay: 20s;
  right: -4em;
}
.chat_text {
  color: black;
}
