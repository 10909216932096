.wrapper {
    display: flex;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'DINNextRounded';
    background-color: #5cfbac;
}

.heart-container {
    position: absolute;
    top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heart-count {
    color: #ee5555;
    font-weight: 700;
    margin-left: 3px;
    font-size: 20px;
}

.container {
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.title-1 {
    margin-top: 10px;
    color: #343535;
    font-weight: 600;
    font-size: x-large;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
}

.link {
    text-decoration: none; /* Removes underline */
    color: inherit; /* Keeps the text color same as parent */
    cursor: pointer;
}

.link:hover {
    color: inherit; /* Prevents color change on hover */
}

.image-blend {
    /* box-shadow: 25px 25px 50px 0 #5cfbac inset, -25px -25px 50px 0 #5cfbac inset; */
    box-shadow: 30px 30px 1px #5cfbac;
    -webkit-box-shadow: 30px 30px 1px #5cfbac;
    -moz-box-shadow: 30px 30px 1px #5cfbac;
}

.buttons-wrapper {

}

.button-container-1 {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.duo-button {
    font-size: 18px;
    font-weight: bold;
    padding: 12px 28px;
    border: none;
    border-radius: 18px;
    cursor: pointer;
    width: 90vw;
    margin-top: 5px;
    margin-bottom: 5px;
    position: relative;
}

.primary {
    background-color: #58cc02;
    color: white;
    box-shadow: 0 5px 0 #4eb302;
}

.primary:hover {
    /* background-color: #53bd02; */
}

.primary:active {
    /* background-color: #3e8b00; */
    transform: translateY(5px);
    box-shadow: 0 0 0 #4eb302;
}

.negative {
    background-color: #e5383b;
    color: white;
    box-shadow: 0 5px 0 #d13234;
}

.negative:hover {
    /* background-color: #d93538; */
}

.negative:active {
    /* background-color: #a61f22; */
    transform: translateY(5px);
    box-shadow: 0 0 0 #d13234;
}

.yes-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -100px;
}

.yes-images-container {
    display: flex;
}

@media (min-width:320px)  { 
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
}
@media (min-width:480px)  { 
    /* smartphones, Android phones, landscape iPhone */ 
}
@media (min-width:600px)  { 
    /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
}
@media (min-width:801px)  { 
    /* tablet, landscape iPad, lo-res laptops ands desktops */ 
}
@media (min-width:1025px) { 
    /* big landscape tablets, laptops, and desktops */ 
    
}
@media (min-width:1281px) { 
    /* hi-res laptops and desktops */ 
    .button-container-1 {
        display: flex;
        flex-direction: row;
    }

    .duo-button {
        font-size: 18px;
        font-weight: bold;
        padding: 12px 28px;
        border: none;
        border-radius: 18px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        width: 20vw;
        margin-left: 5px;
        margin-right: 5px;
        /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
    }

    .wrapper {
        justify-content: center;
    }
}

.shake {
    animation: shake 0.5s ease-in-out;
  }
  
  @keyframes shake {
    0% { transform: translateX(0); }
    20% { transform: translateX(-7px); }
    40% { transform: translateX(7px); }
    60% { transform: translateX(-7px); }
    80% { transform: translateX(7px); }
    100% { transform: translateX(0); }
  }

.particles-container {
    position: absolute;
    top: 0;
    z-index: 0;
    pointer-events: none;
}